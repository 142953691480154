import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'

// Libraries]
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import SwiperCore, { Pagination } from 'swiper'
import { motion } from 'framer-motion'

// Images
import Pin from 'img/pin.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

SwiperCore.use([Pagination])

interface HighlightedProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const HighlightedProjects: React.FC<HighlightedProjectsProps> = ({
  fields,
}) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedProjectsQueryQuery>(graphql`
    query highlightedProjectsQuery {
      allWpProject(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProject.edges
  const postIds = fields.projects?.map((post) => post?.databaseId)

  return (
    <section>
      <BlogBink
        showIds={postIds as Array<number>}
        posts={posts as BlogBinkPosts}
        id="highlightedProjects"
        limit={Infinity}
      >
        <div>
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const SwiperContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.75rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;

  & .swiper {
    padding-right: 1.5rem;
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Title = styled(ParseContent)`
  & h2 {
    font-size: 35px;
    line-height: 40px;
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: 24px;
      line-height: 28px;
    }
  }
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <>
      <div className="container" ref={containerRef}>
        <Title content={fields.title || ''} />
        <div className="row mt-5 g-3 d-lg-flex d-none">
          {blogBink.posts.map((post) => {
            const { node }: any = post

            return (
              <div key={post.node.uri} className="col-lg-4">
                <BlogGridPost node={node} />
              </div>
            )
          })}
          <div className="col-12 mt-lg-5 mt-3 d-flex justify-content-center">
            {/* @ts-ignore */}
            <ButtonSecondary to="/projecten/">
              {fields.loadmoretext}
            </ButtonSecondary>
          </div>
        </div>
      </div>

      {marginLeft !== null && (
        <SwiperContainer
          className="container mt-lg-0 mt-3 d-lg-none"
          marginLeft={marginLeft}
        >
          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.75,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
          >
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <SwiperSlide key={node.uri}>
                  <BlogGridPost node={node} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperContainer>
      )}
    </>
  )
}

interface BlogGridPostProps {
  node: GatsbyTypes.WpProject
}

const StyledBlogGridPost = styled(motion.div)`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 200px);
  z-index: 4 !important;
  padding: 35px;

  & p {
    color: ${({ theme }) => theme.color.light};
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 0.5rem;
  }

  @media screen and (max-width: 575px) {
    padding: 15px;

    & p {
      margin-bottom: 0 !important;
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: relative;
  z-index: 1 !important;
  border-radius: 15px;
  height: 250px;
  object-fit: cover;

  & img {
    border-radius: 15px;
  }
`

const Gradient = styled.div`
  position: absolute;
  height: 135px;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 150px);
  z-index: 2;
  background: linear-gradient(180deg, rgba(28, 26, 51, 0) 0%, #1c1a33 76.07%);
  border-radius: 15px;
`

const Solid = styled(motion.div)`
  position: absolute;
  transform: translate3d(0, 0, 175px);
  z-index: 3;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.tertiary};
  border-radius: 0 0 15px 15px;
`

const Location = styled.div`
  & div {
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-transform: uppercase;
    font-size: 14px;
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <StyledBlogGridPost
    initial="init"
    whileHover="hover"
    onClick={() => navigate(node.uri || '/')}
  >
    <StyledPlaatjie loading="eager" image={node.projectrecap?.image} alt="" />
    <Gradient />
    <Solid
      variants={{
        hover: {
          height: 110,
        },
        init: {
          height: 0,
        },
      }}
    />
    <Content>
      <ParseContent content={node.projectrecap?.excerpt || ''} />
      {node.projectrecap?.location && (
        <Location className="d-flex align-items-center">
          <Pin />
          <div className="ms-1">{node.projectrecap.location}</div>
        </Location>
      )}
    </Content>
  </StyledBlogGridPost>
)

export default HighlightedProjects
